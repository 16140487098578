<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import Banners from "@/components/widgets/banners";
import Stat from "@/components/widgets/stat";
import { VueSvgGauge } from "vue-svg-gauge";

export default {
  locales: {
    pt: {
      'Team': 'Time',
      'Dashboard': 'Painel',
      'Package': 'Pacote',
      'Graduation': 'Graduação',
      'Points': 'Pontos',
      'Referral link': 'Link de indicação',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
      'Total Bonus': 'Total de Bônus',
      'Total Points': 'Total de Pontos',
    },
    es: {
      'Team': 'Equipo',
      'Dashboard': 'Panel',
      'Package': 'Paquete',
      'Graduation': 'Graduación',
      'Points': 'Puntos',
      'Referral link': 'Link de patrocineo',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
      'Total Bonus': 'Total de Bônus',
      'Total Points': 'Total de Puntos',
    }
  },
  components: {
    Layout,
    Banners,
    Stat,
    VueSvgGauge
  },
  computed: {
    ...mapState({
      account: state => state.account
    }),
  },
  data() {
    return {
      total: {
        bonus: "US$ -.--",
        balance: "US$ -.--",
      },

      premiations: null,

      statData: null,
    };
  },
  methods: {
    getTotalBonus() {
      api
        .get('report/bonus/total')
        .then(response => {
          if (response.data.status=='success') {
            this.total.bonus = this.$options.filters.currency(response.data.total)
            this.getStatData();
          }
        })
    },
    getTotalBalance() {
      api
        .get('wallet/balance/network')
        .then(response => {
          if (response.data.status=='success') {
            this.total.balance = this.$options.filters.currency(response.data.balance)
            this.getStatData();
          }
        })
    },
    getStatData() {
      this.statData = [
        {
          title: "Total de Bônus",
          value: this.total.bonus,
        },
        {
          title: "Saldo Disponível",
          value: this.total.balance
        }
      ]
    },
    getPremiations() {
      api.get("report/premiations").then((response) => {
        if (response.data.status === "success") {
          this.premiations = response.data;
        }
      });
    },
  },
  mounted() {
    this.getTotalBonus()
    this.getTotalBalance()
    this.getStatData()
    this.getPremiations()
  }
};
</script>

<template>
  <Layout>
    <Banners></Banners>

    <div class="card">
      <div class="card-body py-2 d-flex align-items-center">
        <div class="mt-1">
          <i class="bx bx-link font-size-20 mr-1"></i>
        </div>
        <h5 class="m-0 align-items-center">
          {{ t("Referral link") }}
        </h5>
        <div class="mt-1 ml-3">
          <ShareNetwork
            network="whatsapp"
            url=""
            :title="'https://anuncios.primemotorsma.com/' + account.user.username"
          >
            <i class="bx bxl-whatsapp font-size-24 text-dark p-0"></i>
          </ShareNetwork>
        </div>
        <div class="mt-1 ml-2">
          <button
            class="btn btn-link p-0 m-0"
            v-clipboard:copy="
              'https://anuncios.primemotorsma.com/' + account.user.username
            "
            v-on:click="$noty.success('O link foi copiado!')"
          >
            <i class="bx bx-copy font-size-20 text-dark"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-4 col-lg-4 col-xl-4">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div
      v-if="premiations && premiations.current"
      class="premiations d-flex"
      :class="premiations.current.image.replace('.jpg', '')"
    >
      <div class="info align-self-center">
        <vue-svg-gauge
          class="chart"
          :start-angle="-110"
          :end-angle="110"
          :value="parseFloat(premiations.current.percent)"
          :separator-step="0"
          :min="Number(0)"
          :max="Number(100)"
          gauge-color="#000"
          :scale-interval="0"
        />
        <div class="points text-white">
          <h1 class="mb-0 text-white">{{ premiations.total | points }}</h1>
          <p>PONTOS</p>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.premiations {
  display: block;
  position: relative;
  background-color: #999;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  margin-bottom: 30px;
  width: 100%;
  height: 400px;
}
.premiations.iphone {
  background-image: url("~@/assets/images/premiations/iphone.jpg");
}
.premiations.2k {
  background-image: url("~@/assets/images/premiations/2k.jpg");
}
.premiations.florida {
  background-image: url("~@/assets/images/premiations/florida.jpg");
}
.premiations.10k {
  background-image: url("~@/assets/images/premiations/10k.jpg");
}
.premiations.mbw {
  background-image: url("~@/assets/images/premiations/bmw.jpg");
}
.premiations .info {
  position: absolute;
  right: 100px;
}
.premiations .info .chart {
  height: 220px;
}
.premiations .info .points {
  position: absolute;
  top: 120px;
  width: 100%;
  text-align: center;
}
.premiations .info .points p {
  line-height: 13px;
}

@media (max-width: 575px) {
  .premiations {
    background-size: 120% 100%;
    height: 300px;
    justify-content: center;
  }
  .premiations .info {
    right: auto;
  }
  .premiations .info .chart {
    height: 190px;
  }

  .premiations .info .points {
    top: 90px;
  }
}
</style>
